import React from 'react';

import classNames from 'classnames';

import { StrapiButton } from '../../../atoms/Buttons/StrapiButton/StrapiButton';
import { Button, Feature, Gradient, Media, Panel, Section } from '../../../models/Strapi';
import Column from '../../../molecules/StrapiComponent/Column/Column';
import FeatureList from '../../../molecules/StrapiComponent/FeatureList/FeatureList';
import { Analytics } from '../../../services/Analytics/Analytics';
import { UrlService } from '../../../services/UrlService';
import styles from './MainModule.css';

type MobilePanelComponent = {
    id: number;
    panels: Panel[];
    moduleScreenId?: string;
};

type MainComponent = {
    id: number;
    advantageImage: Media;
    sideImage: Media;
    features: Feature[];
    CTA: Button;
    moduleScreenId?: string;
    gradient: Gradient;
    content: string;
};

type MainModuleProps = {
    title: string;
    component: [MainComponent, MobilePanelComponent];
    background: Media;
    backgroundFallback: string;
};

const MobilePanelComponent = (content: any) => {
    return (
        <div className={styles.mobilePanels} style={{ backgroundColor: content.color }}>
            {content.mobilePanelComponent?.panels.map((panel) => {
                return (
                    <Column
                        key={panel.id}
                        panel={panel}
                        moduleScreenId={content.mobilePanelComponent.moduleScreenId || {}}
                        mobileAltVersion={true}
                    />
                );
            })}
        </div>
    );
};

const MainModule = React.memo((props: MainModuleProps) => {
    const {
        title,
        backgroundFallback,
        component: [mainComponent, mobilePanelComponent],
    } = props;

    const getBackground = ({ background, backgroundFallback }: MainModuleProps) => {
        const result = {} as React.CSSProperties;
        if (background) {
            result.backgroundImage = `url(${UrlService.createStrapiMediaUrl(background.url)})`;
        }
        result.backgroundColor = backgroundFallback;
        return result;
    };

    const ctaLabel = mainComponent.CTA.label;
    const moduleScreenId = mainComponent.moduleScreenId;

    const handleAnalytics = (ctaText, moduleScreenId) => {
        Analytics.trackEvent(
            Analytics.pageBuilderAdvantageAnalytics.mainModuleClick({
                moduleScreenId,
                ctaText,
            })
        );
    };

    return (
        <>
            <div
                className={classNames(styles.content)}
                style={getBackground(props)}
            >
                <div className={styles.leftSide}>
                    <img
                        src={UrlService.createStrapiMediaUrl(mainComponent.sideImage.url)}
                        alt={mainComponent.sideImage.alternativeText}
                    />
                </div>
                <div className={styles.rightSide}>
                    <img
                        src={UrlService.createStrapiMediaUrl(mainComponent.advantageImage.url)}
                        alt={mainComponent.advantageImage.alternativeText}
                    />
                    <h2>{title}</h2>
                    <FeatureList features={mainComponent?.features} regularBullets={true} />
                    <p className={classNames(styles.text, styles.textBox)}>{mainComponent.content}</p>
                    <StrapiButton
                        label={mainComponent.CTA.label}
                        action={mainComponent.CTA.action}
                        url={mainComponent.CTA.url}
                        gradient={{
                            color1: mainComponent.CTA.gradientColor1, //'#C28C2B'
                            color2: mainComponent.CTA.gradientColor2, //'#E6C14E',
                            color3: mainComponent.CTA.gradientColor3, //'#C28C2B',
                            textColor: mainComponent.CTA.textColor, //'rgba(35, 31, 32, 1)',
                        }}
                        analyticsCallback={() => handleAnalytics(ctaLabel, moduleScreenId)}
                    />
                </div>
            </div>
            {mobilePanelComponent && (
                <MobilePanelComponent mobilePanelComponent={mobilePanelComponent} color={backgroundFallback} />
            )}
        </>
    );
});

export default MainModule;
