import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { push } from 'connected-react-router';
import { batch, useDispatch, useSelector } from 'react-redux';

import styles from './StrapiButton.css';
import { globalErrorHandler } from '../../../../utils/LogUtils';
import { environment } from '../../../config/environment';
import { HeaderSideMenuTabs } from '../../../constants/HeaderSideMenuTabs';
import { RecurlySubscriptionNames } from '../../../constants/SubscriptionPlan';
import { Gradient } from '../../../models/Strapi';
import { AppInsightService } from '../../../services/AppInsight';
import PaymentService from '../../../services/PaymentService';
import UserService from '../../../services/UserService';
import { setSideMenuActivePage } from '../../../store/ducks/layout';
import { setActiveSubscriptionPlan } from '../../../store/ducks/subscription/common';
import { setHostedLoginToken } from '../../../store/ducks/user';

type StrapiButtonProps = {
  action: 'newTab' | 'currentTab' | 'openSidebar' | 'purchase';
  url: HeaderSideMenuTabs | RecurlySubscriptionNames | string;
  label: string;
  analyticsCallback: Function;
  gradient?: Gradient;
  mobileAltVersion?: boolean;
  twoColumnsDesign?: boolean;
  className?: string;
  Icon?: React.ComponentType<any>;
};

type ConfigBtn = {
  borderWidth: string;
  color: string;
  background: string;
};

export const StrapiButton = React.memo(
  ({
    action,
    url,
    label,
    gradient,
    analyticsCallback,
    mobileAltVersion,
    twoColumnsDesign,
    Icon
  }: StrapiButtonProps) => {
    const activeUserSubscriptions = useSelector((state) => state.activeUserSubscriptions);
    const hostedLoginToken = useSelector((state) => state.hostedLoginToken);
    const router = useSelector((state) => state.router);
    const isAdvantagePage = router.location.pathname.includes('advantage');
    const dispatch = useDispatch();
    const [configBtn, setConfigBtn] = useState<ConfigBtn>();
    const fetchRecurlyToken = async () => {
      try {
        const res = await PaymentService.getRecurlyData();

        dispatch(setHostedLoginToken(res.hostedLoginToken));
      } catch (err) {
        globalErrorHandler({ error: err, filename: 'StrapiButton.tsx', info: 'fetchRecurlyToken()' });
        AppInsightService.trackAppError(err, { data: 'getRecurlyData()' });
      }
    };

    useEffect(() => {
      if (isAdvantagePage && activeUserSubscriptions?.length > 0 && !hostedLoginToken) {
        fetchRecurlyToken();
      }
    }, [hostedLoginToken, activeUserSubscriptions]);

    useEffect(() => {
      const config = {
        borderWidth: gradient !== null ? '0' : '3px',
        color: gradient?.textColor === null ? 'currentcolor' : gradient?.textColor,
        background:
          gradient !== null
            ?
            `linear-gradient(314.74deg, ${gradient.color1} 12.84%, ${gradient.color2} 48.8%, ${gradient.color3} 84.98%)`
            :
            'transparent'
      };

      setConfigBtn(config);
    }, []);

    const onClick = (e) => {
      e.preventDefault();
      analyticsCallback();

      switch (action) {
        default:
        case 'newTab':
          setTimeout(() => window.open(url, '_blank'), 100);
          break;
        case 'currentTab':
          setTimeout(() => window.location.assign(url), 100);
          break;
        case 'openSidebar':
          const toOpen = url as HeaderSideMenuTabs;

          if (toOpen === HeaderSideMenuTabs.LOG_IN_TAB && UserService.isUserLoggedIn()) {
            setTimeout(() => window.location.assign('/profile'), 100);
            return;
          }

          dispatch(setSideMenuActivePage(toOpen));
          break;
        case 'purchase':
          const isSubscriber = UserService.isUserSubscriber();

          if (isSubscriber) {
            setTimeout(
              () => window.location.assign(`${environment.RECURLY_BILLING_PAGE}${hostedLoginToken}`),
              100
            );
          } else {
            batch(() => {
              dispatch(setActiveSubscriptionPlan(url));
              dispatch(push('/subscription'));
            });
          }

          break;
      }
    };

    return (
      <div className={Icon && styles.buttonWrapper}>
        <button
          type="button"
          role={action === 'openSidebar' ? undefined : 'link'}
          className={classNames(styles.CTA, {
            [styles.mobileAltVersion]: mobileAltVersion,
            [styles.twoColumnsDesign]: twoColumnsDesign
          })}
          style={{
            borderWidth: configBtn?.borderWidth,
            color: configBtn?.color,
            background: configBtn?.background
          }}
          onClick={onClick}
        >
          {Icon && <Icon />}
          {label}
        </button>
      </div>
    );
  }
);
