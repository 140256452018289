import React from 'react';

import classNames from 'classnames';

import { Feature } from '../../../models/Strapi';
import styles from './FeatureList.css';

type FeatureListProps = {
    features: Feature[];
    regularBullets?: boolean;
};

const FeatureList = React.memo(({ features, regularBullets = false }: FeatureListProps) => {
    return (
        <ul className={styles.features}>
            {features.map((feature, index) => (
                <li
                    key={index}
                    className={classNames(styles.feature, {
                        [styles.hidden]: !feature.active,
                        [styles.regularBullets]: regularBullets,
                    })}
                >
                    <img width={24} height={24} src={'/svg/feature_tick.svg'} alt="Feature tick" />
                    {feature.label.value}
                </li>
            ))}
        </ul>
    );
});

export default FeatureList;
